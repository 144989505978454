.footer {
    flex-shrink: 0;
    margin-top: auto;
    &-top {
        background-color: var(--White);
        padding: 48px 0;
        &__item {
            margin-left: 125px;
            flex-shrink: 0;
            &:first-of-type {
                width: 236px;
                animation-duration: 0.4s;
            }
            &:nth-of-type(2) {
                animation-duration: 1s;
            }
            &:nth-of-type(3) {
                animation-duration: 1.5s;
            }
        }
        &__title {
            display: block;
        }
        &__info {
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            margin: 10px 0 0;
            font-family: var(--otherFont);
        }
        &__logo {
            margin-right: 45px;
        }
    }
    &-bottom {
        background-color: var(--bgColor);
        padding: 18px 0 15px;
        color: var(--White);
    }
}
@media(max-width: 1300px) {
    .footer {
        &-top {
            &__item {
                margin-left: 70px;
            }
        }
    }
}
@media(max-width: 1100px) {
    .footer {
        &-top {
            &__logo {
                margin-right: 20px;
            }
            &__item {
                margin-left: 50px;
            }
        }
    }
}
@media(max-width: 991px) {
    .footer {
        &-top {
            &__inner {
                flex-wrap: wrap;
            }
            &__logo {
                margin-right: 0;
                width: 50%;
            }
            &__item {
                width: 50%;
                margin-left: 0;
                padding-right: 10px;
                box-sizing: border-box;
                &:first-of-type {
                    width: 50%;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
@media(max-width: 575px) {
    .footer {
        &-top {
            &__logo, &__item, &__item:first-of-type {
                width: 100%;
                margin-bottom: 20px;
            }
            &__item:last-child {
                margin-bottom: 0;
            }
        }
    }
}