.form {
    &-top {
        background-image: url(../assets/images/form-bg-add.png), url(../assets/images/form-bg.jpg);
        background-repeat: no-repeat, no-repeat;
        background-size: auto, cover;
        background-position: center calc(100% - 30px), center;
        padding: 56px 0 96px;
        overflow: hidden;
        input {
            margin-right: 12px;
            &:nth-of-type(1) {
                animation-duration: .5s;
            }
            &:nth-of-type(2) {
                animation-duration: 1.1s;
            }
            &:nth-of-type(3) {
                animation-duration: 1.6s;
            }
        }
        .btn-type2 {
            flex-shrink: 0;
            animation-duration: 2s;
        }
    }
    &-bottom {
        background-color: var(--formBg);
        overflow: hidden;
        &__inner {
            position: relative;
            z-index: 1;
            input {
                margin-bottom: 20px;
                &:nth-of-type(1) {
                    animation-duration: .5s;
                }
                &:nth-of-type(2) {
                    animation-duration: 1.1s;
                }
                &:nth-of-type(3) {
                    animation-duration: 1.6s;
                }
            }
            form {
                width: 479px;
            }
        }
        .container {
            position: relative;
            padding-top: 285px;
            padding-bottom: 285px;
        }
        &__img {
            position: absolute;
            z-index: 0;
            right: -83px;
            top: 120px;
        }
        .btn-type2 {
            flex-shrink: 0;
            animation-duration: 2s;
        }
    }
}
@media(max-width: 1300px) {
    .form {
        &-bottom {
            &__img {
                right: -350px;
            }
        }
    }
}
@media(max-width: 900px) {
    .form {
        &-top__inner {
            flex-wrap: wrap;
            justify-content: center;
            input {
                margin: 0 0 20px;
            }
        }
    }
}
@media(max-width: 860px) {
    .form {
        &-bottom {
            overflow: hidden;
            .container {
                padding-top: 85px;
                padding-bottom: 85px;
            }
            &__img {
                width: 100%;
                right: 0;
                top: 0;
                opacity: 0.15;
            }
        }
    }
}
@media(max-width: 575px) {
    .form {
        &-bottom {
            &__inner {
                form {
                    width: 100%;
                }
            }
        }
    }
}