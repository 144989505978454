body {
    margin: 0;
}

.body__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: var(--mainFont);
    font-size: var(--fontSize);
    font-weight: 400;
    color: var(--mainColor);
    line-height: 1;
    overflow: hidden;
    main {
        flex-shrink: 0;
    }
}
.list-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.def-link {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    &:hover, &:focus {
        text-decoration: none;
        outline: none;
    }
}

.title-h1,
.title-h2,
.title-h3,
.title-h4,
.title-h5,
.title-h6,
.title-p {
    margin: 0;
}

.title-h1, .title-h2 {
    font-size: 60px;
    line-height: 1;
}
.title-h2, .typography h2 {
    margin-bottom: 40px;
    &::first-letter {
        color: var(--brandColor);
    }
}
.title-h3 {
    font-size: 40px;
    line-height: 1.2;
}
.title-h4 {
    font-size: 25px;
}
.title-h5 {
    font-size: 24px;
}
.title-h6 {
    font-size: 22px;
}
.title-p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
}
.swiper-button-prev:after, .swiper-button-next:after {
    display: none;
}
.swiper-button-prev1, .swiper-button-next1, .swiper-button-prev2, .swiper-button-next2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 48px;
    height: 48px;
    /* border-radius: 50% !important;
    padding: 0 !important; */
    opacity: 0;
    z-index: 1;
    transition: all 0.2s;
    &:after {
        display: none;
    }
    &:focus {
        outline: none;
    }
}
.swiper-button-prev1, .swiper-button-prev2, .swiper-button-next1, .swiper-button-next2 {
    i {
        display: inline-block;
    }
}
.swiper-button-prev1, .swiper-button-prev2 {
    i {
        margin-left: -1px;
    }
}
.swiper-button-next1, .swiper-button-next2 {
    i {
        margin-right: -3px;
    }
}
.tab {
    display: flex;
    /* overflow-y: hidden;
    overflow-x: auto; */
    &-container:not(.tab-page__active) {
        display: none;
    }
    li {
        flex-shrink: 0;
    }
}
@media(max-width: 575px) {
    .title-h1, .typography h1, .title-h2, .typography h2 {
        font-size: 40px;
    }
    .title-h3, .typography h3 {
        font-size: 24px;
    }
    .title-h4, .typography h4 {
        font-size: 22px;
    }
    .title-h5, .typography h5 {
        font-size: 20px;
    }
    .title-h6, .typography h6 {
        font-size: 16px;
    }
    .title-p {
        font-size: 16px;
    }
    .swiper-button-prev1, .swiper-button-next1, .swiper-button-prev2, .swiper-button-next2 {
        opacity: 1;
    }
}