/* fixed-bg */
.fixed-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
/* page-up */
.page-up {
    position: fixed;
    right: 69px;
    bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border: 3px solid var(--btnBorder);
    background-color: var(--btnBorder);
    color: var(--White);
    font-size: 35px;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 2;
    transition: all .2s;
    &:hover {
        background-color: var(--White);
        color: var(--mainColor);
    }
}
@media(max-width: 767px) {
    .page-up {
        right: 30px;
    }
}

/* input */
.input {
    -webkit-appearance: none;
    padding: 13.5px 5px 13.5px 15px;
    background: transparent;
    border: 3px solid var(--inputBorder);
    width: 100%;
    font-size: 20px;
    color: var(--mainColor);
    box-sizing: border-box;
    &::placeholder {
        color: var(--mainColor);
        padding: 0;
    }
    &:-webkit-outer-spin-button,
    &:-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    &:focus {
        outline: none;
    }
}
@media(max-width: 575px) {
    .input {
        font-size: 18px;
    }
}

/* animations */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1s;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 1s;
}

@keyframes backInUp {
  0% {
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.backInUp {
  animation-name: backInUp;
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0) scaleX(0.98);
  }

  90% {
    transform: translate3d(5px, 0, 0) scaleX(0.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
.zoomIn {
  animation-name: zoomIn;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}