.app {
    padding: 55px 0 58px;
    &__title {
        text-align: center;
        text-transform: uppercase;
        span {
            color: var(--brandColor);
        }
    }
    &__block {
        align-items: center;
        margin-top: 43px;
        .typography {
            padding-left: 50px;
            animation-duration: 0.7s;
        }
        img {
            animation-duration: 1.7s;
        }
        img, .typography {
            width: 50%;
        }
        &:nth-child(odd) {
            flex-direction: row-reverse;
            .typography {
                padding: 0 50px 0 0;
            }
        }
    }
}
@media(max-width: 991px) {
    .app {
        &__block {
            flex-wrap: wrap;
            &:nth-child(odd) {
                flex-direction: row-reverse;
                .typography {
                    padding: 0;
                }
            }
            img, .typography {
                width: 100%;
            }
            img {
                margin-top: 20px;
                order: 2;
            }
            .typography {
                padding: 0;
                order: 1;
            }
        }
    }
}