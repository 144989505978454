.btn {
    background: none;
    border: none;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}
.btn-type1, .btn-type2 {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: var(--White);
    overflow: hidden;
    transition: all .2s;
    &:before {
        content: "\E001";
        font-family: "icons";
        font-size: 15px;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid var(--btnBorder);
        margin-right: 15px;
        box-sizing: border-box;
        transition: all .2s;
    }
    &:hover {
        background-color: var(--btnBorder);
        padding-right: 30px;
        &:before {
            content: "\E002";
            animation-name: fade-in;
            animation-duration: .5s;
            animation-iteration-count: 1;
        }
    }
}
.btn-type2 {
    padding: 13px 20px;
    border: 3px solid var(--btnBorder);
    color: var(--mainColor);
    &:before {
        border: none;
        width: auto;
        height: auto;
        transition: all .2s;
    }
    &:hover {
        color: var(--White);
        padding-right: 20px;
    }
}
@keyframes fade-in {
    0%{
        transform: translateX(-50px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}
@media(max-width: 575px) {
    .btn-type1, .btn-type2 {
        font-size: 16px;
        &:before {
            font-size: 13px;
        }
    }
}