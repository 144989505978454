.example {
    background: url(../assets/images/example-bg.jpg) 50% 0 / cover;
    padding: 50px 0;
    &__inner {
        padding: 40px 15px;
        background-color: var(--White);
        &-title {
            text-align: center;
        }
    }
    &__item {
        max-width: 25%;
        width: 100%;
        margin-top: 30px;
        &-format {
            font-size: 20px;
            text-transform: uppercase;
            text-align: center;
            margin: 0;
        }
        &-img {
            width: 100%;
            height: 315px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 22px;
        }
        .btn-type1 {
            color: var(--mainColor);
            margin: 30px auto 0 auto;
            &:hover {
                color: var(--White);
            }
        }
        &:nth-of-type(1) {
            animation-duration: .7s;
        }
        &:nth-of-type(2) {
            animation-delay: .5s;
            animation-duration: .7s;
        }
        &:nth-of-type(3) {
            animation-delay: 1s;
            animation-duration: .7s;
        }
        &:nth-of-type(4) {
            animation-delay: 1.5s;
            animation-duration: .7s;
        }
    }
}
@media(max-width: 950px) {
    .example {
        &__item {
            &-img {
                height: 220px;
            }
        }
    }
}
@media(max-width: 767px) {
    .example {
        &__item {
            max-width: 50%;
            &-img {
                height: 315px;
            }
            .btn-type1 {
                margin: 20px auto 0 auto;
            }
        }
        .flexRow {
            flex-wrap: wrap;
        }
    }
}
@media(max-width: 450px) {
    .example {
        &__item {
            max-width: 100%;
        }
    }
}