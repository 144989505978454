.info-block {
    background: url(../assets/images/leicadistos910-bg.jpg) 50% 50% / cover;
    padding: 90px 0;
    &__inner {
        align-items: center;
        .flexCell {
            max-width: 50%;
            width: 100%;
        }
    }
    &__img {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        animation-duration: 1s;
    }
    &__text {
        animation-duration: 1.9s;
    }
    &__btns {
        margin-top: 50px;
        .btn-type2:first-child {
            margin-right: 20px;
        }
    }
    &__bottom {
        position: relative;
        padding: 30px 23px;
        margin-top: 48px;
        font-size: 20px;
        line-height: 30px;
        border: solid;
        border-image-source: url(../assets/images/border.png);
        border-image-slice: 7%;
        border-image-width: auto;
        border-image-repeat: round;
        p {
            margin: 0;
        }
        span {
            color: var(--brandColor);
            font-family: 'UiEmoji';
        }
    }
}
@media(max-width: 1024px) {
    .info-block {
        &__img {
            img {
                width: 100%;
            }
        }
    }
}
@media(max-width: 991px) {
    .info-block {
        &__inner {
            position: relative;
        }
        &__text.flexCell, &__img.flexCell {
            max-width: 70%;
        }
        &__img {
            position: absolute;
            right: -300px;
        }
    }
}
@media(max-width: 767px) {
    .info-block {
        padding: 60px 0;
        &__img {
            right: -225px;
        }
        &__btns {
            margin-top: 40px;
        }
    }
}
@media(max-width: 575px) {
    .info-block {
        padding: 60px 0 40px;
        &__inner {
            flex-wrap: wrap;
        }
        &__img {
            position: static;
            margin-top: 40px;
        }
        &__text.flexCell, &__img.flexCell {
            max-width: 100%;
        }
    }
}
@media(max-width: 400px) {
    .info-block {
        &__btns {
            flex-wrap: wrap;
            .btn-type2 {
                width: 100%;
                &:first-child {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}