.about {
    background: url(../assets/images/about-bg.jpg) 50% 50% / cover;
    padding: 210px 0;
    overflow: hidden;
    &__inner {
        align-items: center;
        color: var(--White);
    }
    &__text, &__img {
        width: 50%;
    }
    &__img {
        animation-duration: 1s;
    }
    &__text {
        padding-right: 20px;
        animation-duration: 1.9s;
        p {
            color: var(--textColor);
            font-size: 20px;
        }
    }
    &__img {
        display: flex;
        justify-content: center;
        &-inner {
            position: relative;
        }
        &-inner:after {
            position: absolute;
            content: "";
            border: 3px solid var(--brandColor);
            width: calc(100% - 120px);
            height: calc(100% + 68px);
            box-sizing: border-box;
            top: -34px;
            left: 60px;
        }
        img {
            position: relative;
            width: 334px;
            z-index: 1;
        }
    }
    &-video {
        background: url(../assets/images/video-bg.jpg) 50% 50% / cover;
        padding: 90px 0 60px;
        .about__img {
            width: auto;
        }
        .about__img-inner {
            width: 563px;
            height: 316px;
        }
        iframe {
            width: 100% !important;
            height: 100% !important;
            position: relative;
            z-index: 1;
        }
        .about__text {
            padding: 0 0 0 55px;
            width: auto;
            flex-grow: 1;
        }
        &__bottom {
            text-align: center;
            text-transform: uppercase;
            color: var(--White);
            padding-top: 50px;
            border-top: 1px dashed var(--borderColor);
            margin-top: 110px;
            animation-duration: 1.5s;
        }
    }
}
@media(max-width: 1220px) {
    .about {
        &-video {
            .about__img-inner {
                width: 450px;
                height: 250px;
            }
        }
    }
}
@media(max-width: 991px) {
    .about {
        padding: 150px 0;
        &-video {
            padding: 90px 0 60px;
            .about__inner {
                flex-wrap: wrap;
            }
            .about__img, .about__text, .about__img-inner {
                width: 100%;
            }
            .about__text {
                padding: 0;
                margin-top: 60px;
            }
            &__bottom {
                margin-top: 60px;
            }
        }
    }
}
@media(max-width: 750px) {
    .about {
        padding: 60px 0 85px;
        &__inner {
            flex-wrap: wrap;
        }
        &__text {
            margin-bottom: 50px;
        }
        &__img {
            margin: 0;
        }
        &__img, &__text {
            width: 100%;
        }
        &-video {
            padding: 85px 0 60px;
            .about__text {
                margin-bottom: 0;
            }
        }
    }
}
@media(max-width: 575px) {
    .about {
        &__text {
            p {
                font-size: 16px;
            }
        }
        &__img {
            &-inner {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
}
@media(max-width: 475px) {
    .about {
        &-video {
            .about__img-inner {
                height: 165px;
            }
        }
    }
}