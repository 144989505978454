/* Default */
@import "default/variables.css";
@import "default/reset.css";
@import "default/grid.css";
@import "default/typography.css";
@import "default/fonts.css";

/* Components */
@import "iconfonts.css";
@import "../templates/components/buttons/buttons.css";
@import "../templates/components/components/components.css";

/* Blocks */
@import "../templates/blocks/header/header.css";
@import "../templates/blocks/footer/footer.css";
@import "../templates/blocks/main-banner/main-banner.css";
@import "../templates/blocks/form/form.css";
@import "../templates/blocks/characteristic/characteristic.css";
@import "../templates/blocks/app/app.css";
@import "../templates/blocks/about/about.css";
@import "../templates/blocks/info-block/info-block.css";
@import "../templates/blocks/example/example.css";