.typography {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    h1, h2, h3, h4, h5, h6, p, blockquote, dl, ol, ul {
        margin: 0;
        font-family: inherit;
        color: inherit;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &:first-child {
            margin-top: 0;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 700;
        margin-top: 30px;
        line-height: 1.2;
        &:not(:last-child) {
            margin-bottom: 17px;
        }
    }

    h1, h2 {
        font-size: 60px;
        line-height: 1;
    }

    h3 {
        font-size: 40px;
    }

    h4 {
        font-size: 25px;
    }

    h5 {
        font-size: 24px;
    }
    
    h6 {
        font-size: 22px;
    }

    a {
        display: inline-block;
        color: var(--brandColor);
        text-decoration: none;
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--brandColorLight);
            margin-top: -2px;
        }
        &:visited {
            color: var(--brandColorLight);
        }
    }

    mark {
        background-color: var(--White);
        border-bottom: 1px solid var(--brandColor);
        color: var(--brandColor);
    }

    small {
        font-size: 80%;
        font-weight: 400;
    }

    b, strong {
        font-weight: 800;
    }

    blockquote {
        position: relative;
        margin: 30px 0;
        background-color: #f8f9fb;
        padding: 35px 25px 35px 53px;
        &:after {
            position: absolute;
            top: 35px;
            left: 25px;
            content: "";
            display: block;
            background-color: var(--brandColor);
            width: 4px;
            height: calc(100% - 70px);
        }
    }

    dl, ol, ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li:not(:last-child) {
        margin-bottom: 17px;
    }

    ol ol, ol ul, ul ol, ul ul {
        margin-bottom: 0;
    }

    ul {
        list-style-position: inside;
        list-style: none;
        li {
            display: flex;
            align-items: flex-start;
            &:before {
                content: "\E00E";
                font-family: "icons";
                color: var(--brandColor);
                font-size: 11px;
                margin-right: 11px;
                margin-top: 2px;
            }
            span {
                display: block;
                flex-shrink: 1;
            }
        }
    }

    ol {
        counter-reset: counter;
        li {
            display: flex;
            counter-increment: counter;
            &:before {
                content: counter(counter) ". ";
                margin-right: 14px;
                font-weight: bold;
                font-size: 15px;
                line-height: 1.5;
                color: var(--brandColor);
            }
            span {
                display: block;
                flex-shrink: 1;
            }
        }
    }

    table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        border-top: 2px solid var(--mainColor);
        border-bottom: 2px solid var(--mainColor);
        th, td {
            text-align: left;
            padding: 17px 10px 15px 30px;
            border: none;
            font-weight: 400;
        }
        th {
            border-right: 1px solid var(--mainColor);
        }
        td {
            width: 393px;
        }
        tr {
            background-color: var(--White);
            transition: all .2s;
            &:nth-child(odd) {
                background-color: var(--tableBg);
            }
            &:hover {
                background-color: var(--brandColor);
                color: var(--White);
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            margin-top: 0;
        }
    }
}
@media(max-width: 900px) {
    .typography {
        table {
            td, th {
                padding-left: 15px;
            }
            td {
                width: auto;
            }
        }
    }
}
@media(max-width: 767px) {
    .typography {
        th, td {
            padding: 15px 10px;
        }
        th:first-child, td:first-child {
            width: auto;
        }
    }
}
@media(max-width: 575px) {
    .typography {
        font-size: 14px;
    }
}