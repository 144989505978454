:root {
    --mainColor: #000000;
    --mainFont: "Roboto-cond";
    --otherFont: 'Roboto';
    --fontSize: 16px;
    --brandColor: #ee0032;
    --White: #ffffff;
    --bgColor: #12181d;
    --borderColor: #353b40;
    --btnBorder: #c30d2f;
    --bannerBg: #0e1216;
    --inputBorder: #acacac;
    --formBg: #ededed;
    --tableBg: #f2f2f2;
    --textColor: #959596;
}
@media(max-width: 575px) {
    :root {
        --fontSize: 14px;
    }
}