@font-face {
	font-family: 'Roboto-cond';
	src:
        url("../assets/fonts/roboto-condensed-regular.ttf"),
        url("../assets/fonts/roboto-condensed-regular.woff"),
        url("../assets/fonts/roboto-condensed-regular.woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
	font-family: 'Roboto';
	src:
        url("../assets/fonts/Roboto-Bold.ttf"),
        url("../assets/fonts/Roboto-Bold.woff"),
        url("../assets/fonts/Roboto-Bold.woff2");
    font-weight: 700;
    font-style: normal;
}
@font-face {
	font-family: 'Roboto-cond';
	src:
        url("../assets/fonts/roboto-condensed-bold.ttf"),
        url("../assets/fonts/roboto-condensed-bold.woff"),
        url("../assets/fonts/roboto-condensed-bold.woff2");
    font-weight: 700;
    font-style: normal;
}
@font-face {
	font-family: 'UiEmoji';
	src:
        url("../assets/fonts/Segoe-ui-emoji.ttf"),
        url("../assets/fonts/Segoe-ui-emoji.woff"),
        url("../assets/fonts/Segoe-ui-emoji.woff2");
    font-weight: 400;
    font-style: normal;
}