@font-face {
	font-family: "icons";
	src: url("../assets/fonts/iconfonts/icons.woff2") format("woff2"),
		url("../assets/fonts/iconfonts/icons.woff") format("woff");
}
[class^="icon-"], [class*="icon-"] {
	font-family: "icons";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
    line-height: 1;
	text-decoration: none;
    text-transform: none;
}


.icon-1-0_plus::before {
	content: "\E001";
}

.icon-1-1_next::before {
	content: "\E002";
}

.icon-1-2_menu::before {
	content: "\E003";
}

.icon-1-3_return::before {
	content: "\E004";
}

.icon-1-4_close::before {
	content: "\E005";
}