.container {
    max-width: 1172px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

.flexRow, .flexColumn, .flexWrap, .flexGrid { 
    display: flex;
}

.flexColumn {
    flex-direction: column;
}

.flexWrap, .flexGrid {
    flex-wrap: wrap;
}

.flexCell {
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
}

.marginIndents {
    margin-left: -15px;
    margin-right: -15px;
}
@media(max-width: 1220px) {
    .container {
        padding: 0 60px;
    }
}
@media(max-width: 800px) {
    .container {
        padding: 0 15px;
    }
}