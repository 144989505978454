.characteristic {
    background: linear-gradient(to top, var(--White), #fbfbfb);
    padding: 60px 0;
    &__title {
        position: relative;
        justify-content: space-between;
        text-transform: uppercase;
        margin-bottom: 35px;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            background-color: var(--brandColor);
            width: 100%;
            height: 18px;
            top: calc(50% - 10px);
            left: 0;
            transform: translateX(-100%);
            transition: all 1s;
        }
        span {
            font-size: 25px;
            font-weight: 700;
            margin-right: 10px;
            padding: 0 10px;
        }
        h4 {
            padding-right: 33px;
        }
        h4, span {
            position: relative;
            line-height: 1;
            background-color: #fbfbfb;
            z-index: 1;
        }
        &-effect {
            &:after {
                transform: translateX(0);
            }
        }
    }
    &__table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        border-top: 2px solid var(--mainColor);
        border-bottom: 2px solid var(--mainColor);
        th, td {
            text-align: left;
            padding: 17px 10px 15px 30px;
            border: none;
            font-weight: 400;
        }
        th {
            border-right: 1px solid var(--mainColor);
        }
        td {
            width: 393px;
        }
        tr {
            background-color: var(--White);
            transition: all .2s;
            &:nth-child(odd) {
                background-color: var(--tableBg);
            }
            &:hover {
                background-color: var(--brandColor);
                color: var(--White);
            }
        }
    }
}
@media(max-width: 900px) {
    .characteristic {
        &__table {
            td, th {
                padding-left: 15px;
            }
            td {
                width: auto;
            }
        }
    }
}
@media(max-width: 575px) {
    .characteristic {
        &__title {
            flex-direction: column;
            align-items: center;
            h4 {
                padding: 0;
                text-align: center;
                margin-bottom: 10px;
            }
            span {
                margin-right: 0;
            }
            &:after {
                top: auto;
                bottom: 3px;
            }
        }
    }
}