.header {
    background: var(--White);
    flex-shrink: 0;
    padding: 30px 0;
    &__inner {
        justify-content: space-between;
        align-items: center;
    }
    &__logo {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }
    &__menu {
        font-size: 20px;
        font-weight: 700;
        a {
            transition: color .2s, border-color .2s;
            &:hover {
                color: var(--brandColor);
                padding-bottom: 10px;
                border-bottom: 3px solid var(--brandColor);
            }
        }
        li:not(:last-child) {
            margin-right: 30px;
        }
        &-active {
            color: var(--brandColor);
            padding-bottom: 10px;
            border-bottom: 3px solid var(--brandColor);
        }
        &-close {
            display: none;
        }
    }
    .btn {
        display: none;
        i {
            font-size: 20px;
        }
    }
    &__fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 8px 0;
        z-index: 2;
        .header__menu {
            font-size: 18px;
        }
        .header__logo img {
            height: 46px;
        }
    }
}
@media(max-width: 767px){
    .header {
        padding: 15px 0;
        .btn {
            display: block;
        }
        &__menu {
            display: none;
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100%;
            background-color: white;
            padding: 100px 15px 10px;
            box-sizing: border-box;
            ul {
                flex-direction: column;
                align-items: center;
            }
            li:not(:last-child) {
                margin: 0 0 30px;
            }
            &-close {
                position: absolute;
                top: 46px;
                right: 15px;
                display: block;
            }
        }
        &__fixed {
            .header__menu {
                font-size: 20px;
            }
        }
    }
}
  