.main-banner {
    padding: 165px 0 145px;
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__title {
        color: var(--White);
    }
    &__price {
        color: var(--brandColor);
        font-size: 35px;
        font-weight: 700;
        margin: 35px 0 0;
    }
    .btn-type1 {
        margin-top: 65px;
    }
}
@media(max-width: 575px) {
    .main-banner {
        padding-top: 40px;
        padding-bottom: 245px;
        background: url(../assets/images/main-banner-mobile.jpg) 50% 50% / cover;
        &__price {
            font-size: 22px;
            margin: 17px 0 0;
        }
        .btn-type1 {
            margin-top: 28px;
        }
    }
}